<template>
  <div class="main-body">
    <!----展示现有手机号页面---->
    <div
      class="phone-box"
      v-if="step === 0"
    >
      <p>当前手机号</p>
      <h3 class="current-phone">{{oldPhone | phoneDecorate}}</h3>
      <div class="bind-text">
        我们将根据您提交的手机号，推送您的车辆信息 请提交正确的手机号
      </div>
      <cube-button
        type="submit"
        class="btn-default"
        @click="goChangePhone()"
      >
        <span>更换手机号</span>
      </cube-button>
    </div>
    <!----输入手机号页面---->
    <div
      v-if="step === 1"
      class="phone-box"
    >
      <cube-input
        class="left input-vcode"
        v-model="phone"
        placeholder="请输入手机号"
        type="text"
      >
      </cube-input>
      <img
        v-if="!!phone"
        @click="phone = ''"
        src="~images/garage/eliminate.png"
        class="close"
        alt=""
      >
      <div class="bind-text">
        我们将根据您提交的手机号，推送您的车辆信息 请提交正确的手机号
      </div>
      <cube-button
        type="submit"
        class="btn-default"
        @click="sendCaptcha()"
      >
        <span>{{isOldUserFlag?'更改手机号':'确认提交'}}</span>
      </cube-button>
    </div>
    <!----发送验证码页面---->
    <div
      class="phone-bind"
      v-if="step === 2"
    >
      <h1>验证码已发送至</h1>
      <h3>{{phone}}</h3> <i>（{{counterTimer}}秒）</i>
      <div
        class="message-vcode"
        @click="focus"
      >
        <input
          type="number"
          id="inputbox"
          size="6"
          oninput="if(value.length>6)value=value.slice(0,6)"
          maxlength="6"
          v-model="code"
          class="input-box"
        />
        <div @click="focus">
          <div class="left input-bottom">{{code[0] ||''}}</div>
          <div class="left input-bottom">{{code[1] ||''}}</div>
          <div class="left input-bottom">{{code[2] ||''}}</div>
          <div class="left input-bottom">{{code[3] ||''}}</div>
          <div class="left input-bottom">{{code[4] ||''}}</div>
          <div class="left input-bottom">{{code[5] ||''}}</div>
        </div>
      </div>
      <cube-button
        type="submit"
        :class="code.length === 6 ?'btn-default' :  'btn-gray'"
        @click="bindPhone()"
      >
        {{isOldUserFlag?'更改手机号':'确定'}}
      </cube-button>
    </div>
    <!----绑定手机成功界面---->
    <div
      class="success-box"
      v-if="step === 3"
    >
      <img
        src="~images/garage/success.png"
        class="success-icon"
        alt=""
      >
      <h1>恭喜您，成功绑定手机</h1>
      <h3>
        我们将根据您提交的手机号，推送您的车辆信息
      </h3>
      <cube-button
        type="submit"
        class="btn-default"
        @click="bindSuccess()"
      >
        完成
      </cube-button>
    </div>
  </div>
</template>
<script>
import { goWXStaticAuth, isWeiXin } from 'utils/wx'
import { getUrlParam } from 'utils/index'
import { getUserStatusByCode, sendCellPhoneCode, bindPhone } from 'api/user'
import { setSessionStorage } from 'utils/store'
export default {
  data () {
    return {
      userinfo: {
        mobilePhone: ''
      },
      oldPhone: '',
      phone: '',
      code: '',
      getVcode: '确认提交',
      counterTimer: 60,
      sendCaptchaEnabled: true,
      isOldUserFlag: false,
      openId: '',
      unionId: '',
      step: 0
    }
  },
  created () {
    if (isWeiXin()) {
      const params = getUrlParam()
      if (!params['code']) {
        console.log(goWXStaticAuth)
        goWXStaticAuth(process.env.VUE_APP_OWNER_APPID, '/g/bind-phone')
      } else {
        const code = params['code']
        this.getUserStatusByCode(code)
      }
    }
  },
  methods: {
    sendCaptcha () {
      if (!this.phone.trim()) {
        const toast = this.$createToast({
          txt: `手机号码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.phone === this.oldPhone) {
        const toast = this.$createToast({
          txt: `新手机号不能与旧手机号一样哦～`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }

      if (!/^1\d{10}$/.test(this.phone.trim())) {
        const toast = this.$createToast({
          txt: `请正确输入11位数字的手机号`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.sendCaptchaEnabled) {
        return
      }
      if (this.sending) {
        return
      }
      this.sending = true
      const params = {
        openId: this.openId,
        phone: this.phone,
        unionId: this.unionId
      }
      sendCellPhoneCode(JSON.stringify(params)).then((res) => {
        this.sending = false
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.sendCaptchaEnabled = false
      })
      let that = this
      this.sendCaptchaEnabled = false
      this.step = 2
      document.title = this.isOldUserFlag ? '更换手机号' : '绑定手机号'
      that.timer = setInterval(() => {
        if (this.counterTimer <= 0) {
          this.counterTimer = 60
          this.sendCaptchaEnabled = true
          this.getVcode = '重新获取'
          clearInterval(that.timer)
        }
        this.counterTimer--
      }, 1000)
    },
    focus () {
      document.getElementById('inputbox').focus()
    },
    // 通过code获取用户手机号信息以及用户是否绑定
    getUserStatusByCode (code) {
      const params = {
        code: code
      }
      getUserStatusByCode(params).then((res) => {
        const { result, desc, data } = res.data
        if (result !== 0) {
          return this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          }).show()
        }
        this.isOldUserFlag = !!data.phone
        this.openId = data.openId
        this.unionId = data.unionId
        if (data.phone) {
          this.step = 0
          this.oldPhone = data.phone
          setSessionStorage('token', data.token)
        } else {
          this.step = 1
          this.isOldUserFlag = false
        }
      })
    },
    bindPhone () {
      if (this.code.length !== 6) return
      let params = {
        openId: this.openId,
        phone: this.phone,
        unionId: this.unionId,
        vcode: this.code
      }
      if (this.oldPhone) params.oldPhone = this.oldPhone
      bindPhone(params).then((res) => {
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.step = 3
        this.oldPhone = this.phone
        this.phone = ''
        this.code = ''
        this.isOldUserFlag = true
      })
    },
    goChangePhone () {
      this.step = 1
    },
    bindSuccess () {
      this.step = 0
      document.title = '手机号'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.main-body {
  width: px2rem(670px);
  margin: 0 auto;
  //输入手机号界面
  .phone-box {
    font-size: px2rem(36px);
    padding-top: px2rem(178px);
    position: relative;
    .input-vcode {
      width: px2rem(670px);
      height: px2rem(50px);
      line-height: px2rem(50px);
      // border-color: white;
      border-bottom: 1px solid #ddd !important;
      outline: none;
      position: relative;
    }
    .current-phone {
      font-size: px2rem(64px);
      padding-top: px2rem(30px);
    }
    .bind-text {
      width: px2rem(588px);
      padding-top: px2rem(40px);
      text-align: left;
      display: inline-block;
      font-size: px2rem(28px);
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: px2rem(40px);
    }
    .get-code {
      color: #fc4c5a;
      font-size: px2rem(12px);
    }
    .close {
      width: px2rem(28px);
      height: px2rem(28px);
      position: absolute;
      right: 0;
      top: px2rem(180px);
    }
  }

  .cube-input::after {
    content: "";
    pointer-events: none;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: 0 0;
    border: none;
    // border-bottom: 1px solid #ddd;
    border-radius: 2px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
  //
  .phone-bind {
    position: relative;
    h1 {
      font-size: px2rem(36px);
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: px2rem(50px);
      padding-top: px2rem(85px);
    }
    h3 {
      font-size: px2rem(64px);
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      line-height: px2rem(74px);
      padding-top: px2rem(30px);
    }
    i {
      font-style: normal;
      color: #fc4c5a;
      font-size: px2rem(24px);
      position: absolute;
      top: px2rem(188px);
      right: 0;
    }
    .message-vcode {
      margin: px2rem(17px) auto 0;
      display: inline-block;
      text-align: center;
      width: 100%;
      .input-bottom {
        height: px2rem(60px);
        width: px2rem(95px);
        margin-left: px2rem(15px);
        // float: left;
        border-bottom: px2rem(2px) solid #ccc;
        font-size: px2rem(60px);
        display: inline-block;
        vertical-align: middel;
      }
    }
    .btn-gray {
      border-bottom: 1px solid #ccc;
      background: #ccc;
      color: #fff;
      width: px2rem(570px);
      height: px2rem(80px);
      border-radius: 6px;
      margin: px2rem(60px) px2rem(50px);
      display: inline-block;
    }
    //以下输入框响应后的按钮颜色
    .btn-orange {
      border-bottom: 1px solid #ccc;
      background: #ff9d4d;
      color: #fff;
      width: px2rem(570px);
      height: px2rem(80px);
      border-radius: 6px;
      margin: px2rem(60px) px2rem(50px);
      display: inline-block;
    }
  }
  //绑定成功界面
  .success-box {
    text-align: center;
    h1 {
      font-size: px2rem(36px);
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: px2rem(50px);
      padding-top: px2rem(65px);
    }
    h3 {
      font-size: px2rem(28px);
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: px2rem(40px);
      padding-top: px2rem(20px);
    }
    .success-icon {
      padding-top: px2rem(95px);
      width: px2rem(280px);
      height: px2rem(266px);
      margin: 0 auto;
    }
  }
  .btn-default {
    width: px2rem(570px);
    height: px2rem(80px);
    background: rgba(255, 157, 77, 1);
    border-radius: 6px;
    margin: px2rem(56px) auto;
  }
}
.input-box {
  outline: none;
  width: 1px;
  height: 0.01px;
  color: transparent;
  caret-color: transparent;
  margin-left: -100%;
  text-indent: -999em;
}
</style>
